<template>
  <div>
    <a-modal
      width="1200px"
      v-model="isShow"
      centered
      :mask-closable="false"
      title="项目划拨批次记录"
    >
      <template slot="footer">
        <a-button type="primary" @click="closeModal">
          关闭
        </a-button>
      </template>
      <a-table
        size="middle"
        :columns="columns"
        :data-source="data"
        :loading="loading"
        :pagination="false"
        :scroll="{ y: 350 }"
        row-key="operation_no"
      />
    </a-modal>
  </div>
</template>

<script>
import { findAgentPurchaseItemAllocationRecords } from '@/api/agent_purchase_item'
export default {
  name: 'AgentPurchaseItemAllocationRecords',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      data: []
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    columns() {
      return [
        {
          title: '划拨批次',
          width: 100,
          dataIndex: 'operation_no'
        },
        {
          title: '项目编号',
          width: 240,
          dataIndex: 'item_no'
        },
        {
          title: '卡号总数',
          width: 100,
          dataIndex: 'operation_count'
        },
        {
          title: '划拨成功数',
          width: 100,
          dataIndex: 'success_count'
        },
        {
          title: '划拨失败数',
          width: 100,
          dataIndex: 'failure_count'
        },
        {
          title: '划拨人',
          width: 100,
          dataIndex: 'operator'
        },
        {
          title: '划拨时间',
          width: 200,
          dataIndex: 'created_at'
        },
        {
          title: '备注',
          width: 150,
          ellipsis: true,
          dataIndex: 'remark'
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findAgentPurchaseItemAllocationRecords(this.id).then(res => {
        if (res.code === 0) {
          this.data = res.data
          this.loading = false
        }
      })
    },

    closeModal() {
      this.isShow = false
    }
  }
}
</script>

<style scoped>

</style>
